// NOTE: This require will be replaced with `@sentry/browser`
// client side thanks to the webpack config in next.config.js
import * as Sentry from '@sentry/node'
import { NextPageContext } from 'next'

const sentryOptions: Sentry.NodeOptions = {
  release: process.env.SENTRY_RELEASE,
  environment: process.env.SITE_ENV,
  dsn: process.env.SENTRY_DSN,
  enabled: process.env.NODE_ENV === 'production',
  maxBreadcrumbs: 50,
  attachStacktrace: true,
}

Sentry.init(sentryOptions)

export { Sentry }

export type SentryError = Error & {
  errorInfo?: React.ErrorInfo | Record<string, any>
  tags?: Record<string, any>
  level?: Sentry.Severity
}

type SentryOptions = {
  ctx?: NextPageContext
  fingerprint?: string
}

export const captureException = (err: SentryError, options?: SentryOptions) => {
  if (!sentryOptions.enabled) {
    console.error('Sentry error:', err)
    options && options.ctx && console.error('NextPageContext:', options.ctx)
    return
  }

  Sentry.configureScope(scope => {
    if (err.message) {
      // De-duplication currently doesn't work correctly for SSR / browser errors
      // so we force deduplication by error message if it is present
      scope.setFingerprint([(options && options.fingerprint) || err.message])
    }

    if (err.level) {
      scope.setLevel(err.level)
    }

    scope.setTag('ssr', !process.browser ? 'true' : 'false')

    if (err.tags) {
      Object.keys(err.tags).forEach(key => scope.setTag(key, err.tags![key]))
    }

    if (err.errorInfo) {
      Object.keys(err.errorInfo).forEach(key =>
        scope.setExtra(key, err.errorInfo![key as keyof React.ErrorInfo])
      )
    }

    if (process.browser) {
      scope.setExtra('cookies', document.cookie)
    }

    if (!options || !options.ctx) {
      return
    }

    const { req, res, query, pathname } = options.ctx

    scope.setExtra('query', query)
    scope.setExtra('pathname', pathname)

    if (res && res.statusCode) {
      scope.setExtra('statusCode', res.statusCode)
    }

    if (req) {
      scope.setExtra('url', req.url)
      scope.setExtra('method', req.method)
      scope.setExtra('headers', req.headers)
    }
  })

  return Sentry.captureException(err)
}
