export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    IMarketable: ['Kit', 'Product', 'Meal'],
    Address: ['DeliveryAddress', 'PickupAddress'],
    INode: [
      'DistributionCenter',
      'Coupon',
      'Sale',
      'Cart',
      'MarketableFilter',
      'Kit',
      'Product',
      'ProductVariant',
      'Meal',
      'Store',
      'DynamicPage',
      'DynamicContainer',
      'Component',
      'DynamicPageController',
      'Highlight',
      'Survey',
      'SurveyAnswer',
    ],
    IPaymentMethod: [
      'BoletoPaymentMethod',
      'CreditCardPaymentMethod',
      'MarketVoucherOfflinePaymentMethod',
      'MarketVoucherOnlinePaymentMethod',
      'RestaurantVoucherOfflinePaymentMethod',
      'RestaurantVoucherOnlinePaymentMethod',
      'TicketOnlinePaymentMethod',
    ],
    CartBillingPaymentMethod: [
      'CartBillingPaymentMethodBoleto',
      'CartBillingPaymentMethodOnline',
      'CartBillingPaymentMethodOffline',
    ],
    ISearchItem: ['Product', 'Meal'],
    IConsumable: ['Product', 'Meal'],
    SimpleComponent: ['Banner', 'HighlightStories', 'Display', 'CMSContainer'],
    ContainerComponentsDynamicZoneUnion: [
      'CMSComponentContainerBanner',
      'CMSComponentContainerCallToAction',
      'CMSComponentContainerDeliveryAreaCard',
      'CMSComponentContainerHeader',
      'CMSComponentContainerImage',
      'CMSComponentContainerProducerAreaCards',
      'CMSComponentContainerProducerCard',
      'CMSComponentContainerQuote',
      'CMSComponentContainerText',
      'CMSComponentContainerVideo',
      'CMSComponentDeliveryAreaCards',
      'CMSComponentHighlightStoryHighlightStories',
      'CMSComponentContainerDisplay',
      'CMSComponentContainerCustomHtml',
      'CMSComponentContainerButton',
      'CMSComponentContainerAccordion',
    ],
  },
}
export default result
