import { DOMAIN, withParams } from '@lojinha/helpers'

export const sanitizeImage = ({
  imageUrl,
  size = 'small',
  desktopImage = false,
  type,
}: {
  imageUrl: string
  size?: 'small' | 'medium' | 'big'
  desktopImage?: boolean
  type?: string
}) =>
  decodeURIComponent(imageUrl)
    .split('?')[0]
    .replace('/site/images/photos/', `/website/images/photos-${size}/`)
    .replace('/site/images/kits/', `/website/images/kits-${size}/`)
    .replace(
      '/website/images/photos-original/',
      `/website/images/photos-${size}/`
    )
    .replace('/website/images/kits-original/', `/website/images/kits-${size}/`)
    .replace('.png', type ?? '.jpg')
    .replace(
      'https://s3-sa-east-1.amazonaws.com/livup-public/',
      'https://static.livup.us/'
    )
    .replace(
      'https://s3.sa-east-1.amazonaws.com/static.livup.us/',
      'https://static.livup.us/'
    )
    .replace(
      'https://s3-sa-east-1.amazonaws.com/static.livup.us/',
      'https://static.livup.us/'
    )
    .replace('https://static.livup.us/', 'https://livup.imgix.net/')
    .replace('https://livup.imgix.net/', '')
    .replace(/[^a-zA-Z0-9-_.:\/\s]/g, '')
    .replace(/\s/g, '-')
    .replace('/banner/', desktopImage ? '/banner-desktop/' : '/banner/')

export const getBannerImageByUrl = (url: string, isDesktop: boolean) => {
  const bannerImageUrl = `${DOMAIN}${sanitizeImage({
    imageUrl: url,
    size: 'big',
    desktopImage: isDesktop,
  })}`

  const lowQualityUrl = withParams(bannerImageUrl, {
    px: 16,
    blur: 200,
    fm: 'webp',
  })

  const optimizedUrl = withParams(bannerImageUrl, {
    fm: 'webp',
  })

  return { lowQualityUrl, optimizedUrl }
}

export const getBannerImageFromStrapiByUrl = (url: string) => {
  const lowQualityUrl = withParams(url, {
    px: 16,
    blur: 200,
    fm: 'webp',
  })

  const optimizedUrl = withParams(url, {
    px: 16,
    blur: 200,
    fm: 'webp',
  })

  return { lowQualityUrl, optimizedUrl }
}
