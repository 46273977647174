import { useEffect, useState } from 'react'

export const DOMAIN = 'https://livup.imgix.net/'

export type Params = {
  w?: number
  h?: number
  q?: number
  px?: number
  blur?: number
  orient?: number
  fm?:
    | 'gif'
    | 'jp2'
    | 'jpg'
    | 'json'
    | 'jxr'
    | 'pjpg'
    | 'mp4'
    | 'png'
    | 'png8'
    | 'png32'
    | 'webm'
    | 'webp'
    | 'blurhash'
}

export const withParams = (url: string, params: Params = {}) => {
  const queryStart = url.includes('?') ? '&' : '?'

  const query = Object.entries(params)
    .map(([k, v]) => `${k}=${v}`)
    .concat(['auto=format'])
    .join('&')

  return `${url}${queryStart}${query}`
}

export const useLqip = (imageUrl: string) => {
  const images = {
    low: withParams(imageUrl, {
      px: 16,
      blur: 200,
    }),
    high: imageUrl,
  }

  const [imageSrc, setImageSrc] = useState(images.low)

  useEffect(() => {
    const img = new Image()
    img.src = images.high
    img.onload = () => {
      setImageSrc(images.high)
    }

    return () => {
      img.onload = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { imageSrc, loaded: imageSrc === images.high }
}
