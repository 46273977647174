import * as brands from '@lojinha/design-system/src/icons'
import { PaymentMethodBrand } from '@lojinha/palantir'

export const brandMethodIcons = {
  [PaymentMethodBrand.Alelo]: brands.Alelo,
  [PaymentMethodBrand.Amex]: brands.Amex,
  [PaymentMethodBrand.Aura]: brands.Aura,
  [PaymentMethodBrand.Ben]: brands.Ben,
  [PaymentMethodBrand.Diners]: brands.Dinners,
  [PaymentMethodBrand.Discover]: brands.Discover,
  [PaymentMethodBrand.Elo]: brands.Elo,
  [PaymentMethodBrand.Greencard]: brands.Greencard,
  [PaymentMethodBrand.Hipercard]: brands.Hipercard,
  [PaymentMethodBrand.Mastercard]: brands.Mastercard,
  [PaymentMethodBrand.SodexoAlimentacao]: brands.Sodexo,
  [PaymentMethodBrand.SodexoRefeicao]: brands.Sodexo,
  [PaymentMethodBrand.Ticket]: brands.Ticket,
  [PaymentMethodBrand.Unknown]: brands.User,
  [PaymentMethodBrand.Verocard]: brands.Verocard,
  [PaymentMethodBrand.Visa]: brands.Visa,
  [PaymentMethodBrand.VrAlimentacao]: brands.VrFood,
  [PaymentMethodBrand.VrRefeicao]: brands.VrMeal,
  [PaymentMethodBrand.UpBrasil]: brands.UpBrasil,
}
