import { NextRouter } from 'next/router'

export const hasSearchParam = (router: NextRouter) =>
  router.query.hasOwnProperty('search')

export const getSearchParam = (router: NextRouter) => {
  const { query } = router
  const searchTerm = Array.isArray(query.search)
    ? query.search.slice(-1).pop()
    : query.search

  return searchTerm || ''
}

export const updateSearchParam = (router: NextRouter, searchTerm = '') =>
  router.push(
    {
      query: {
        ...router.query,
        search: searchTerm,
      },
    },
    undefined,
    { shallow: true }
  )

export const removeSearchParam = (router: NextRouter) => {
  const { search, ...queries } = router.query
  router.push({
    query: queries,
  })
}
