import React from 'react'
import Head from 'next/head'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { getDataFromTree } from '@apollo/client/react/ssr'
import { getAuthToken } from './authToken'
import { initApollo } from './initApollo'
import {
  AppWithApollo,
  AppWithApolloInitialContext,
  AppWithApolloProps,
} from './apolloTypes'
import { User } from './palantirTypes'

const IS_SERVER = typeof window === 'undefined'

export type VitrineCustomProps = {
  user?: User
  authToken: string | null
}

export function withApolloClient<AppInitialProps>(
  App: AppWithApollo<AppInitialProps>
) {
  return class Apollo extends React.Component {
    static displayName = 'withApollo(App)'
    private apolloClient: ApolloClient<NormalizedCacheObject>

    static async getInitialProps({
      AppTree,
      ctx,
      Component,
    }: AppWithApolloInitialContext) {
      ctx.authToken = getAuthToken(ctx)
      ctx.apolloClient = initApollo({}, ctx)

      const appProps = App.getInitialProps ? await App.getInitialProps(ctx) : {}
      const pageProps = Component.getInitialProps
        ? await Component.getInitialProps(ctx)
        : {}

      if (IS_SERVER) {
        try {
          await getDataFromTree(
            <AppTree
              apolloClient={ctx.apolloClient}
              appProps={appProps}
              pageProps={pageProps}
            />
          )
        } catch (error) {
          console.error('Error while running `getDataFromTree`', error)
        }

        Head.rewind()
      }

      const apolloState = ctx.apolloClient?.cache.extract()

      return {
        apolloState,
        appProps,
        pageProps,
      }
    }

    constructor(
      props: Omit<AppWithApolloProps<VitrineCustomProps>, 'apolloClient'>
    ) {
      super(props)
      this.apolloClient = initApollo(props.apolloState)
    }

    render() {
      return <App apolloClient={this.apolloClient} {...(this.props as any)} />
    }
  }
}
