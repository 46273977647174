import { captureException } from '@lojinha/sentry'

export const getLocalStorage = (key: string) => {
  if (typeof window === 'undefined') return null
  try {
    return window.localStorage.getItem(key)
  } catch (error) {
    captureException(error, { fingerprint: 'Storage getItem not working!' })
    return null
  }
}

export const setLocalStorage = (key: string, value: string) => {
  if (typeof window === 'undefined') return

  try {
    window.localStorage.setItem(key, value)
  } catch (error) {
    captureException(error, { fingerprint: 'Storage setItem not working!' })

    if (error.message.includes('exceeded the quota')) {
      localStorage.clear()
      window.localStorage.setItem(key, value)
    }
  }
}

export const removeLocalStorage = (key: string) => {
  if (typeof window === 'undefined') return

  try {
    window.localStorage.removeItem(key)
  } catch (error) {
    captureException(error, { fingerprint: 'Storage removeItem not working!' })
  }
}
