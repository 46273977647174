type BreakpointsEnum = 'sm' | 'md' | 'lg'

type WindowSizes = number[] & { [key in BreakpointsEnum]: number }

const windowSizes = [48, 79, 100] as WindowSizes
windowSizes.sm = windowSizes[0]
windowSizes.md = windowSizes[1]
windowSizes.lg = windowSizes[2]

export const windowSizesPx = [768, 1264, 1600] as WindowSizes
windowSizesPx.sm = windowSizesPx[0]
windowSizesPx.md = windowSizesPx[1]
windowSizesPx.lg = windowSizesPx[2]

export const breakpoints = windowSizes.map(
  (bp: number) => `${bp}em`
) as string[]

export const media = {
  above(size: BreakpointsEnum | number) {
    return `@media (min-width: ${windowSizes[size]}em)`
  },
  below(size: BreakpointsEnum | number) {
    const idx = windowSizes.indexOf(windowSizes[size]!)
    const breakpoint = windowSizes[idx - 1] || windowSizes[0]
    return `@media (max-width: ${breakpoint - 0.0625}em)`
  },
}
